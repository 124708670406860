/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, ContactForm, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Contact"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-yuet0w css-42e4bw --style2 --left --full" name={"uvod"} fullscreen={true} animS={"7"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/38872/6156fb9dc49147d9805549cb67e55d53_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/38872/6156fb9dc49147d9805549cb67e55d53_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/38872/6156fb9dc49147d9805549cb67e55d53_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/38872/6156fb9dc49147d9805549cb67e55d53_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/38872/6156fb9dc49147d9805549cb67e55d53_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/38872/6156fb9dc49147d9805549cb67e55d53_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/38872/6156fb9dc49147d9805549cb67e55d53_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/38872/6156fb9dc49147d9805549cb67e55d53_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pl--0" anim={"7"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--102" style={{"maxWidth":""}} content={"<span style=\"color: white;\">Contact</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --anim-s2" name={"contact"} style={{"paddingTop":112,"paddingBottom":88}} anim={"2"} animS={"2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"paddingTop":0}}>
              
              <ContactForm className="--shape2 --style3 --shadow2 ff--2 fs--16 w--400 ls--002 lh--16" style={{"maxWidth":800,"paddingTop":0}} action={"/contact"} fields={[{"name":"Name","type":"text","required":true,"placeholder":"Name","id":"r7jyqkdwfi8a"},{"name":"Email","type":"email","required":true,"placeholder":"Email","id":"yc3q9e9y5e7"},{"name":"Phone","type":"text","placeholder":"Phone","id":"vq32kjm87ppl"},{"name":"Message","type":"textarea","required":true,"placeholder":"Message","id":"tsc91xzb8hmr"},{"name":"Send","type":"submit","id":"334pdlnd5swb","align":"center"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--02" name={"bottom"} style={{"backgroundColor":"rgba(192, 192, 192, 1)","marginTop":38,"paddingTop":38,"paddingBottom":0}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--1" style={{"maxWidth":1291,"marginBottom":0}} columns={"1"}>
            
            <ColumnWrapper className="--center mb--02 mt--02" style={{"paddingTop":0,"paddingBottom":0,"paddingLeft":0,"paddingRight":0}}>
              
              <Title className="title-box title-box--style2 title-box--center fs--43 swpf--uppercase" content={"<a href=\"/contact\">Contact</a>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":334}} content={"www.smartmedicalpad.com<br>info@smartmedicalpad.com"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(224, 224, 224, 1)","marginTop":0}} layout={"l1"} name={"kh8dvsp8m4"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0}}>
              
              <Title className="title-box title-box--style5 title-box--center fs--26" style={{"paddingTop":8,"paddingBottom":6}} content={"Disclaimer"}>
              </Title>

              <Text className="text-box text-box--center fs--18 mt--0" content={"All patient names and data showed in all pictures&nbsp;on this website are completely imaginary and not real. It serves only to demonstrative purposes."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}